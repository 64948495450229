import {
  format,
  formatDistance,
  addSeconds,
  parseISO,
  compareDesc
} from 'date-fns'
import { id } from 'date-fns/locale'
import { isNil } from './utils'

const getFormattedDate = (date) => {
  return format(date, "cccc, dd MMMM yyyy 'pukul' p", { locale: id })
}

export const momentAgo = (datetime) => {
  if (isNil(datetime) === false) {
    return formatDistance(new Date(datetime), new Date(), { locale: id, addSuffix: true })
  } else {
    return 'n/a'
  }
}

export const parseDate = (datetime) => getFormattedDate(new Date(datetime))

export const createDateFromSeconds = (seconds) => addSeconds(new Date(), seconds)

export const isExpireDate = (datetime) => {
  const expDate = parseISO(datetime)

  return compareDesc(new Date(), expDate) < 0
}
