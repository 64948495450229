import Service from './main'
import { Student as Url } from './urls'

export default {
  getStudentGradeTranscripts: facultyCode => mhsNiu => {
    return Service.get(Url.GET_GRADE_TRANSCRIPTS, {
      mhsNiu
    }, facultyCode)
  },
  getAllStudentSemester: facultyCode => mhsNiu => {
    return Service.get(Url.GET_ALL_SEMESTER, {
      mhsNiu
    }, facultyCode)
  },
  getAllStudyPlan: facultyCode => mhsNiu => {
    return Service.get(Url.GET_ALL_STUDY_PLAN, {
      mhsNiu
    }, facultyCode)
  },
  getAllStudyResults: facultyCode => (mhsNiu, sempSemId) => {
    return Service.get(Url.GET_ALL_STUDY_RESULTS, {
      mhsNiu, sempSemId
    }, facultyCode)
  }
}
