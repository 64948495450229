import Vue from 'vue'
import { isNil } from '@/helpers'
import {
  ReferenceService,
  LectureService
} from '@/services'

const getDefaultFacultyState = {
  facultyId: null,
  activeSemesterId: null,
  keteranganNilai: []
}

export default {
  namespaced: true,
  state: getDefaultFacultyState,
  getters: {
    getFacultyId: state => state.facultyId,
    getSemesterActive: state => state.activeSemesterId
  },
  mutations: {
    setFacultyCode (state, facultyCode) {
      Vue.set(state, 'facultyId', facultyCode)
    },
    setActiveSemester (state, active) {
      Vue.set(state, 'activeSemesterId', active)
    },
    setKeteranganNilai (state, ketNilai) {
      Vue.set(state, 'keteranganNilai', ketNilai)
    },
    clearAllState (state) {
      Object.assign(state, getDefaultFacultyState)
    }
  },
  actions: {
    facultyCode ({ commit }, facultyCode) {
      commit('setFacultyCode', facultyCode)
    },
    async keteranganNilai ({ commit }) {
      const { data } = await ReferenceService.getGradeReference()
      commit('setKeteranganNilai', data)
    },
    async getActiveSemester ({ commit, rootState }, dsnProdiKode) {
      if (isNil(rootState.komponenPenilaian.semesterId)) {
        const { sempSemId } = await LectureService.getActiveSemester(dsnProdiKode)
        commit('setActiveSemester', sempSemId)
      }
    }
  }
}
