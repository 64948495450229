export default function (disableMenu) {
  return {
    items: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'house-fill',
        badge: {
          variant: 'primary'
        },
        attributes: { disabled: disableMenu }
      },
      {
        title: true,
        name: 'KRS'
      },
      {
        title: true,
        name: 'Nilai'
      },
      {
        name: 'Kartu Hasil Studi',
        url: '/mahasiswa/hasil-studi',
        icon: 'card-text',
        attributes: { disabled: disableMenu }
      },
      {
        title: true,
        name: 'Pengguna'
      },
      {
        name: 'Profil',
        url: '/profil',
        icon: 'person-square',
        attributes: { disabled: disableMenu }
      },
      {
        name: 'Keluar',
        url: '/logout',
        icon: 'power',
        class: 'mt-auto',
        variant: 'success'
      }
    ]
  }
}
