import {
  find, propEq, mapObjIndexed, hasPath, zipWith, map
} from './utils'

export const findObjectInArray = (objArr, propName, propVal) => {
  return find(propEq(propName, propVal), objArr)
}

export const mergeAssessment = (assessComponent, assessments, krsdtId) => {
  const finded = item => {
    const finded = findObjectInArray(assessments, 'komPenId', item.komPenId)
    if (typeof finded !== 'undefined') {
      return Object.assign(finded, {
        action: 'edit',
        krsdtId: krsdtId
      })
    } else {
      return {
        action: 'add',
        komPenId: item.komPenId,
        krsdtId: krsdtId,
        nilai: null
      }
    }
  }

  const merge = map(finded)

  return merge(assessComponent)
}

export const mergeAssessmentError = (assessments, error) => {
  const merger = (assessment, err) => {
    if (hasPath(['message', 'nilai'], err.data)) {
      assessment.state = false
      assessment.errorText = err.data.message.nilai[0]
    } else if (hasPath(['message'], err.data)) {
      assessment.state = false
      assessment.errorText = err.data.message
    }

    return assessment
  }

  return zipWith(merger, assessments, error)
}

export const delay = (delayed) => Promise.resolve(setTimeout(delayed))

export const assessmentComponent = {
  totalPercentage (item) {
    const reducer = (vals, { komPenPersen }) => parseFloat(komPenPersen) + vals
    const total = item.reduce(reducer, 0.00)
    return total.toPrecision()
  },
  percentageLimit: 100.00
}

export const activeSemesterButtonState = (
  lectureCanEntry,
  activeSemester,
  componentExist
) => {
  if (lectureCanEntry) {
    if (activeSemester) {
      if (componentExist) {
        return false
      }
    }
  }

  return true // disable
}

const mergeErrorHandler = message => message[0]
export const formError = error => mapObjIndexed(mergeErrorHandler, error.message)

export function activeSemesterObject (fromList, activeSemesterId) {
  return findObjectInArray(fromList, 'smtId', activeSemesterId)
}

export * from './utils'
export * from './portal'
export * from './date'
export * from './auth'
export * as Sheet from './sheet'
