import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'

// All Vuex Store
import Users from './users'
import KomponenPenilaian from './komponen_penilaian'
import Assessment from './assessment'
import Faculty from './faculty'
import Krs from './krs'
import Dashboard from './dashboard'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users: Users,
    komponenPenilaian: KomponenPenilaian,
    assessment: Assessment,
    faculty: Faculty,
    krs: Krs,
    dashboard: Dashboard
  },
  strict: true,
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    }
  })]
})
