import Vue from 'vue'
import { isEmpty, isNil } from '@/helpers'
import {
  LectureService,
  StudentService,
  UserService
} from '@/services'

const getDefaultKrsState = {
  semesterId: null,
  semesterNama: null,
  semesterList: [],
  semesterStudentList: [],
  selectedKrs: [],
  krs: [],
  countSelectedKrs: 0,
  countKrs: 0,
  mahasiswa: {},
  krsDetail: {},
  hasilStudiDetail: {},
  transkripDetail: {},
  loadingSemester: false,
  loadingMahasiswa: false,
  loadingHasilStudi: false,
  loadingKrsDetil: false,
  loadingKrs: false,
  loadingTranskrip: false
}

export default {
  namespaced: true,
  state: getDefaultKrsState,
  getters: {
    getAllSelectedKrs: state => state.selectedKrs,
    getIndeterminate: state => (state.countSelectedKrs > 0) &&
      (state.countSelectedKrs < state.countKrs),
    getCountSelectedKrs: state => state.countSelectedKrs
  },
  mutations: {
    setSelectedSemesterId (state, semesterId) {
      Vue.set(state, 'semesterId', semesterId)
    },
    setSelectedSemesterName (state, semesterNama) {
      Vue.set(state, 'semesterNama', semesterNama)
    },
    setSelectedKrs (state, krs) {
      Vue.set(state, 'countSelectedKrs', krs.length)
      Vue.set(state, 'selectedKrs', krs)
    },
    setTotalKrs (state, count) {
      Vue.set(state, 'countKrs', count)
    },
    setMahasiswa (state, mhs) {
      Vue.set(state, 'mahasiswa', mhs)
    },
    setKrsDetail (state, krsDetail) {
      Vue.set(state, 'krsDetail', krsDetail)
    },
    setKrs (state, krs) {
      Vue.set(state, 'krs', krs)
    },
    setHasilStudiDetail (state, krsDetail) {
      Vue.set(state, 'hasilStudiDetail', krsDetail)
    },
    setSemesterList (state, semesterList) {
      Vue.set(state, 'semesterList', semesterList)
    },
    setSemesterStudentList (state, semesterList) {
      Vue.set(state, 'semesterStudentList', semesterList)
    },
    setTranskripData (state, transkrip) {
      Vue.set(state, 'transkripDetail', transkrip)
    },
    setLoadingSemester (state, loading) {
      Vue.set(state, 'loadingSemester', loading)
    },
    setLoadingMahasiswa (state, loading) {
      Vue.set(state, 'loadingMahasiswa', loading)
    },
    setLoadingHasilStudi (state, loading) {
      Vue.set(state, 'loadingHasilStudi', loading)
    },
    setLoadingKrsDetil (state, loading) {
      Vue.set(state, 'loadingKrsDetil', loading)
    },
    setLoadingKrs (state, loading) {
      Vue.set(state, 'loadingKrs', loading)
    },
    setLoadingTranskrip (state, loading) {
      Vue.set(state, 'loadingTranskrip', loading)
    },
    flushMahasiswa (state) {
      Vue.set(state, 'semesterId', null)
      Vue.set(state, 'semesterStudentList', [])
      Vue.set(state, 'mahasiswa', {})
      Vue.set(state, 'krsDetail', {})
      Vue.set(state, 'hasilStudiDetail', {})
      Vue.set(state, 'transkripDetail', {})
    },
    clearAllState (state) {
      Object.assign(state, getDefaultKrsState)
    }
  },
  actions: {
    async getLecturerClass ({ rootState }) {
      if (!isNil(rootState.komponenPenilaian.semesterId)) {
        return await LectureService.getLectureClass(
          rootState.users.user.username, rootState.komponenPenilaian.semesterId
        )
      }
    },
    async approveKrs ({ rootState }, approvedKrs) {
      const krsData = approvedKrs.map((item) => ({ krsdtId: item.krsdtId, krsdtApproved: !item.krsdtApproved }))
      const approving = LectureService.approveKrs(
        rootState.faculty.facultyId
      )
      return await approving(krsData)
    },
    async getStudentProfile ({ commit }, mhsNiu) {
      commit('setLoadingMahasiswa', true)
      const data = await UserService.getProfile({
        username: mhsNiu,
        role: 'mahasiswa'
      })
      commit('setMahasiswa', data)
      commit('setLoadingMahasiswa', false)
      return true
    },
    async getAllStudyPlan ({ commit, rootState, state }) {
      commit('setLoadingKrsDetil', true)
      commit('setLoadingKrs', true)
      const { data, info, jmlData } = await StudentService.getAllStudyPlan(
        rootState.faculty.facultyId
      )(state.mahasiswa.mhsNiu)
      commit('setTotalKrs', jmlData)
      commit('setKrsDetail', info)
      commit('setLoadingKrsDetil', false)
      commit('setKrs', data)
      commit('setLoadingKrs', false)
    },
    async getAllStudyResults ({ commit, rootState, state }) {
      if (!isNil(state.semesterId)) {
        commit('setLoadingHasilStudi', true)
        const { data, info } = await StudentService.getAllStudyResults(
          rootState.faculty.facultyId
        )(state.mahasiswa.mhsNiu, state.semesterId)
        commit('setHasilStudiDetail', info)
        commit('setLoadingHasilStudi', false)
        return { data }
      }
    },
    async getStudentGradeTranscripts ({ commit, rootState, state }) {
      commit('setLoadingTranskrip', true)
      const { data, info } = await StudentService.getStudentGradeTranscripts(
        rootState.faculty.facultyId
      )(state.mahasiswa.mhsNiu)
      commit('setTranskripData', {
        jumlahSksDiambil: info.jumlahSksDiambil,
        jumlahMatakuliahDiambil: info.jmlData,
        ipIp: info.ipIpk
      })
      commit('setLoadingTranskrip', false)
      return { data }
    },
    async getAllSemester ({ commit, rootState, state }) {
      if (isEmpty(state.semesterList)) {
        const dosenId = rootState.users.user.username
        commit('setLoadingSemester', true)
        const data = await LectureService.getAllSemester(dosenId)
        commit('setSemesterList', data)
        commit('setLoadingSemester', false)
      }
    },
    async getAllStudentSemester ({ commit, rootState, state }) {
      commit('setLoadingSemester', true)
      const { data } = await StudentService.getAllStudentSemester(
        rootState.faculty.facultyId
      )(state.mahasiswa.mhsNiu)
      commit('setSemesterStudentList', data)
      commit('setLoadingSemester', false)
      return true
    },
    getActiveSemester ({ commit, rootState }) {
      LectureService.getActiveSemester(
        rootState.users.profile.dsnProdiKode
      ).then(({ data }) => {
        commit('setSelectedSemesterId', data.sempSemId)
      })
    },
    getGuidanceList ({ rootState }) {
      return LectureService.getGuidanceList(
        rootState.faculty.facultyId
      )(
        rootState.users.user.username
      )
    }
  }
}
