import Excel from 'exceljs'
import DownloadFile from 'js-file-download'
import {
  takeLast,
  isNil,
  isEmpty,
  find as findBy,
  propEq,
  has as hasProp,
  join,
  map,
  filter,
  pipe,
  prop,
  flatten,
  is
} from './utils'

const autoColumnWidth = (column) => {
  let maxWidth = 10
  column.eachCell((cell) => {
    if (!cell.isMerged && cell.value) {
      const text = cell.value.toString()

      const values = text.split(/[\n\r]+/)

      for (const value of values) {
        const width = value.length + 1

        maxWidth = Math.max(maxWidth, width)
      }
    }
  })

  return maxWidth
}

const readAsArrayBuffer = (arrBuff) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = (e) => reject(new Error(join(' ', ['Error reading file[] :', e.target.result])))
    reader.readAsArrayBuffer(arrBuff)
  })
}

const getAssessmentByName = name => assessments => {
  const assess = findBy(propEq('komPenNama', name))(assessments)
  return hasProp('nilai')(assess) ? assess.nilai : null
}

export const readAssessmentTemplate = (file) => {
  const workbook = new Excel.Workbook()

  return readAsArrayBuffer(file).then((data) => {
    return workbook.xlsx.load(data).then((workbook) => {
      const workSheet = workbook.worksheets[0]
      const rowCount = workSheet.actualRowCount
      const columnCount = workSheet.actualColumnCount
      const baseRowData = workSheet.getSheetValues()

      const rowAssComp = 5
      const defaultHeaderLength = 4
      const defaultHeaderRow = 6
      const assCompKeyBase = takeLast(columnCount - defaultHeaderLength, baseRowData[rowAssComp])
      const filteredAssessmentComponentKeys = assCompKeyBase.filter(it => {
        return ['Id', 'NIM', 'Nama'].includes(it) === false
      })
      const rowData = takeLast(rowCount - defaultHeaderRow, baseRowData)

      const mapGradeAssessmentComponent = item => takeLast(item.length - 1, item)
      const filterKrsdtIdIsNotNil = item => !isNil(item[1])
      const mapAssessmentComponentKeys = item => {
        const rowObject = {
          krsdtId: item[1]
        }
        const assCompVal = takeLast(item.length - defaultHeaderLength, item)
        filteredAssessmentComponentKeys.forEach((it, idx) => {
          rowObject[it] = assCompVal[idx] !== '' ? assCompVal[idx] : null
        })

        return rowObject
      }

      const parsedRowData = pipe(
        map(mapGradeAssessmentComponent),
        filter(filterKrsdtIdIsNotNil),
        map(mapAssessmentComponentKeys)
      )

      return [
        filteredAssessmentComponentKeys,
        parsedRowData(rowData)
      ]
    })
  })
}

export const createAssessmentTemplate = (
  header, headerComponent, arrData, namaDosen, detail, fileName
) => {
  // Initialize
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet(detail.mkkurKode)
  // Filling data

  // Cell Property
  const cellBordered = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' }
  }

  // Preparing header info, header table
  // Merge Cell header data
  const maxColHeader = headerComponent.length + 4

  worksheet.mergeCells('A1:B1')
  worksheet.mergeCells('A2:B2')
  worksheet.mergeCells('A3:B3')
  worksheet.mergeCells('A4:A5')
  worksheet.mergeCells(1, 3, 1, maxColHeader)
  worksheet.mergeCells(2, 3, 2, maxColHeader)
  worksheet.mergeCells(3, 3, 3, maxColHeader)
  worksheet.mergeCells(4, 2, 4, 4)
  worksheet.mergeCells(4, 5, 4, 4 + headerComponent.length)

  // Header Info
  worksheet.getCell('A1').value = 'Matakuliah'
  worksheet.getCell('A2').value = 'Nama Dosen'
  worksheet.getCell('A3').value = 'Nama Kelas'

  // Filling header data
  worksheet.getCell('C1').value = detail.klsMakulNama
  worksheet.getCell('C2').value = namaDosen
  worksheet.getCell('C3').value = detail.klsNama

  // Header
  const fontBold = { name: 'Calibri', size: 11, bold: true }
  const middleCenterAlign = { vertical: 'middle', horizontal: 'center' }

  worksheet.getCell('A4').value = 'No'
  worksheet.getCell('A4').alignment = middleCenterAlign
  worksheet.getCell('A4').style = { font: fontBold }
  worksheet.getCell('B4').value = 'Mahasiswa'
  worksheet.getCell('B4').style = { font: fontBold }
  worksheet.getCell('B4').alignment = middleCenterAlign
  worksheet.getCell('E4').value = 'Rincian Penilaian'
  worksheet.getCell('E4').style = { font: fontBold }
  worksheet.getCell('E4').alignment = Object.assign(middleCenterAlign, { wrapText: true })

  let numberHead = 1
  for (const head of header) {
    worksheet.getRow(5).getCell(numberHead + 1).value = head
    worksheet.getRow(5).getCell(numberHead + 1).style = { font: fontBold }
    worksheet.getRow(5).getCell(numberHead + 1).alignment = middleCenterAlign
    numberHead++
  }

  let numberComp = 1
  for (const head of headerComponent) {
    worksheet.getRow(5).getCell(numberComp + 4).value = head.komPenNama
    worksheet.getRow(5).getCell(numberComp + 4).style = { font: fontBold }
    worksheet.getRow(5).getCell(numberComp + 4).alignment = middleCenterAlign
    worksheet.getRow(6).getCell(numberComp + 4).value = head.komPenPersen
    worksheet.getRow(6).getCell(numberComp + 4).style = {
      font: { ...fontBold, color: { argb: 'FF6A5ACD' } }
    }
    worksheet.getRow(6).getCell(numberComp + 4).alignment = middleCenterAlign
    numberComp++
  }

  // Merge Cell
  worksheet.mergeCells(6, 1, 6, 4)

  // Filling table data
  let numberData = 1
  const rowNumberForData = worksheet.lastRow.number
  for (const data of arrData) {
    const rowNumber = rowNumberForData + numberData

    worksheet.getRow(rowNumber).getCell(1).value = numberData
    worksheet.getRow(rowNumber).getCell(2).value = data.krsdtId
    worksheet.getRow(rowNumber).getCell(3).value = data.mhsNiu
    worksheet.getRow(rowNumber).getCell(4).value = data.mhsNama

    if (!isEmpty(data.assessments)) {
      for (let ci = 0; ci < headerComponent.length; ci++) {
        const assessKey = headerComponent[ci].komPenNama
        worksheet.getRow(rowNumber).getCell(ci + 5).value = getAssessmentByName(assessKey)(data.assessments)
      }
    } else {
      for (let ci = 0; ci < headerComponent.length; ci++) {
        worksheet.getRow(rowNumber).getCell(ci + 5).value = null
      }
    }
    numberData++
  }

  // Autofit columns width
  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      if (cell.model.style.alignment === undefined) {
        cell.model.style.alignment = { vertical: 'middle' }
      }

      if (row.number > 6 && cell.col > 4) {
        cell.model.style.alignment = middleCenterAlign
      }

      cell.border = cellBordered
    })
  })

  // static column width
  worksheet.getColumn(1).width = 4
  worksheet.getColumn(2).width = 10
  worksheet.getColumn(3).width = 11
  if (headerComponent.length === 1) {
    worksheet.getColumn(5).width = 12
  } else {
    worksheet.getColumn(5).width = 7
  }

  worksheet.getColumn(4).width = autoColumnWidth(worksheet.getColumn(4))

  // Writing file
  workbook
    .xlsx
    .writeBuffer()
    .then(buffer => DownloadFile(buffer, fileName))
}

export const normalizeRowSheet = (assessmentComponent, filteredAssessmentComponentKeys, rowData, entryby) => {
  return pipe(
    map(row => {
      return filteredAssessmentComponentKeys.map(key => {
        let nilai

        if (is(Object, row[key])) {
          console.log(row)
          // assumes it object { formula: '', result: '' }
          nilai = hasProp('result')(row[key])
            ? (isNil(prop('result', row[key])) === false ? prop('result', row[key]) : null) : null
        } else {
          nilai = row[key]
        }
        return {
          krsdtId: row.krsdtId,
          entryby,
          kompenId: getKomPenId(assessmentComponent, key),
          nilai
        }
      })
    }),
    flatten
  )(rowData)
}

function getKomPenId (assessmentComponent, name) {
  const obj = findBy(propEq('komPenNama', name), assessmentComponent)
  return prop('komPenId', obj)
}
