import { requireAuth } from '@/helpers/auth'

export default [
  {
    path: '/dosen',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "main" */ '@/layouts/Dosen'),
    meta: {
      label: 'Dosen'
    },
    children: [
      {
        path: '',
        redirect: () => ({ name: 'main' })
      },
      // Nilai
      {
        path: 'kelola-nilai',
        name: 'dosen.kelola-nilai',
        component: () => import(/* webpackChunkName: "dosen" */ '@/views/nilai/Kelola'),
        meta: {
          label: 'Pengelolaan Nilai'
        }
      },
      {
        path: ':kelasId/komponen-nilai',
        name: 'dosen.komponen-nilai',
        component: () => import(/* webpackChunkName: "dosen" */ '@/views/nilai/KelolaKomponen'),
        meta: {
          label: 'Kompenen Nilai'
        }
      },
      {
        path: ':kelasId/nilai-mahasiswa',
        name: 'dosen.mahasiswa-nilai',
        component: () => import(/* webpackChunkName: "dosen" */ '@/views/nilai/KelolaMahasiswa'),
        meta: {
          label: 'Nilai Mahasiswa'
        }
      },
      {
        path: ':kelasId/import-nilai',
        name: 'dosen.import-mahasiswa-nilai',
        component: () => import(/* webpackChunkName: "dosen" */ '@/views/nilai/Import'),
        meta: {
          label: 'Import Nilai Mahasiswa'
        }
      },
      // Akademik
      {
        path: 'bimbingan-akademik/:mhsNiu/mahasiswa',
        name: 'dosen.detail-akademik-mahasiswa',
        component: () => import(/* webpackChunkName: "mahasiswa" */ '@/views/akademik/Bimbingan'),
        meta: {
          label: 'Bimbingan Akademik Mahasiswa'
        }
      },
      {
        path: 'bimbingan-akademik',
        name: 'dosen.bimbingan-akademik',
        component: () => import(/* webpackChunkName: "dosen" */ '@/views/akademik/Kelola'),
        meta: {
          label: 'Bimbingan Akademik'
        }
      }
    ]
  }
]
