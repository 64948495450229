export default function (disableMenu) {
  return {
    items: [
      {
        name: 'Beranda',
        url: '/dashboard',
        icon: 'house-fill',
        badge: {
          variant: 'success'
        },
        attributes: { disabled: disableMenu }
      },
      {
        title: true,
        name: 'Akademik'
      },
      {
        name: 'Bimbingan Akademik',
        url: '/dosen/bimbingan-akademik',
        icon: 'inbox-fill',
        attributes: { disabled: disableMenu }
      },
      {
        title: true,
        name: 'Nilai'
      },
      {
        name: 'Pengelolaan Nilai',
        url: '/dosen/kelola-nilai',
        icon: 'file-spreadsheet-fill',
        attributes: { disabled: disableMenu }
      },
      {
        title: true,
        name: 'Pengguna'
      },
      {
        name: 'Profil',
        url: '/profil',
        icon: 'person-square',
        attributes: { disabled: disableMenu }
      },
      {
        name: 'Keluar',
        url: '/logout',
        icon: 'power',
        class: 'mt-auto',
        variant: 'success'
      }
    ]
  }
}
