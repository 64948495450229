import { join } from '@/helpers'
import Service from './main'
import { Lecture as Url } from './urls'

const komponenPenilaian = {
  getAllKomponenNilai: facultyCode => id => {
    return Service.get(
      Url.GET_ALL_ASSESSMENT_COMPONENT, { id }, facultyCode
    )
  },
  getKomponenNilaiMaster: facultyCode => {
    return Service.get(
      Url.GET_MASTER_ASSESSMENT_COMPONENT, {}, facultyCode
    )
  },
  createKomponenNilai: facultyCode => (params, formData) => {
    return Service.post(
      Url.CREATE_ASSESSMENT_COMPONENT, formData, facultyCode, params
    )
  },
  updateKomponenNilai: facultyCode => (id, params, formData) => {
    return Service.patch(
      join('/', [Url.UPDATE_ASSESSMENT_COMPONENT, id]), formData, facultyCode, params
    )
  },
  destroyKomponenNilai: facultyCode => (id, params) => {
    return Service.delete(
      join('/', [Url.DESTROY_ASSESSMENT_COMPONENT, id]), facultyCode, params
    )
  }
}

const grades = {
  createGradeByClass: facultyCode => (params, formData) => {
    return Service.post(
      Url.CREATE_GRADE_PER_CLASS, formData, facultyCode, params
    )
  },
  createGradeByAssessComponent: facultyCode => (params, formData) => {
    return Service.post(
      Url.CREATE_GRADE_PER_ASSESS_COMPONENT, formData, facultyCode, params
    )
  },
  createGradeByStudent: facultyCode => (params, formData) => {
    return Service.post(
      Url.CREATE_GRADE_PER_STUDENT, formData, facultyCode, params
    )
  },
  updateGradeByStudent: facultyCode => (id, params, formData) => {
    return Service.patch(
      join('/', [Url.UPDATE_GRADE_PER_STUDENT, id]), formData, facultyCode, params
    )
  }
}

export default {
  // No need facultyId
  getAllSemester (lectureId) {
    return Service.get(Url.GET_ALL_SEMESTER, {
      id: lectureId
    })
  },
  async getAllLectureGradeEntries () {
    return await Service.get(Url.DASHBOARD_INTERNAL_GRADE_MONITORING)
  },
  async getActiveSemester (prodiKode) {
    const response = await Service.get(join('/', [Url.GET_ACTIVE_SEMESTER, prodiKode]))
    return response.data
  },
  getLectureClass (lectureId, semesterId) {
    return Service.get(Url.GET_ALL_COURSE, {
      dsnid: lectureId,
      smtid: semesterId
    })
  },
  // End No need facultyId
  getAllMahasiswaByKelasMatkul: facultyCode => (classId, lectureId) => {
    const params = {
      dsnid: lectureId,
      klsid: classId
    }

    return Service.get(Url.GET_ALL_STUDENTS, params, facultyCode)
  },
  getMatakuliah: facultyCode => (classId, dsnId) => {
    return Service.get(Url.GET_COURSE, {
      klsid: classId,
      dsnid: dsnId
    }, facultyCode)
  },
  ...komponenPenilaian,
  ...grades,
  checkGrantAssessment: facultyCode => async (prodiKode, semId, dosenId, kelasId) => {
    const response = await Service.get(Url.CAN_ENTRY_GRADE_PER_STUDENT, {
      sempProdiKode: prodiKode,
      sempSemId: semId,
      dsnid: dosenId,
      klsid: kelasId
    }, facultyCode)

    return response.data
  },
  getGuidanceList: facultyCode => dosenId => {
    return Service.get(Url.GET_GUIDANCE_LIST, {
      dsnid: dosenId
    }, facultyCode)
  },
  approveKrs: facultyCode => async selectedStudyPlans => {
    const { data } = await Service.post(Url.APPROVE_STUDY_PLANS, {
      kode: selectedStudyPlans
    }, facultyCode)

    return data
  },
  getDashboardBimbinganAkademik: facultyCode => dosenId => {
    return Service.get(Url.DASHBOARD_BIMBINGAN_AKADEMIK, {
      dsnid: dosenId
    }, facultyCode)
  },
  getDashboardNilai: dosenId => {
    return Service.get(Url.DASHBOARD_NILAI, {
      dsnid: dosenId
    })
  },
  sendGradeArchive: async ({ grade, template }) => {
    const formData = new FormData()
    formData.append('grade', grade)
    formData.append('template', template)

    return Service.post(Url.SEND_GRADE_ARCHIVE, formData)
  }
}
