import Store from '@/stores'
import { isExpireDate } from './date'

function getTokenExpirationDate () {
  return Store.state.users.tokenExpiresAt
}

function isAccessToken () {
  return Store.state.users.accessToken
}

function tokenExpired () {
  if (getTokenExpirationDate() === null) {
    return true
  } else {
    return isExpireDate(getTokenExpirationDate())
  }
}

export function requireAuth (to, from, next) {
  if (tokenExpired() || !isAccessToken()) {
    Store.dispatch('users/signout').then(() => {
      next({
        name: 'auth.login',
        query: { redirect: to.fullPath }
      })
    })
  } else {
    next()
  }
}

export const redirectIfNotGrantedByRole = (role, redirectTo) => vm => {
  if (vm.userDetail.role === role) {
    return
  }

  return vm.$router.push(redirectTo)
}
