import Vue from 'vue'
import { UserService } from '@/services'
import { createDateFromSeconds, isNil, prop } from '@/helpers'
import selectMenu from '@/modules/menu'

const throttleMessage = [
  'Anda sudah melakukan percobaan login lebih dari 5 kali.',
  'Mohon menunggu dalam 1 menit untuk login kembali.'
].join(' ')

const getDefaultUsersState = {
  authenticated: false,
  accessToken: null,
  tokenExpiresAt: null,
  user: {},
  profile: {},
  throttle: 0,
  modalThrottle: false,
  throttleMessage
}

export default {
  namespaced: true,
  state: getDefaultUsersState,
  getters: {
    getUserThrottle: state => state.throttle,
    getUserDetail: state => state.user,
    getUserProfile: state => state.profile,
    getUserEmailNotExist: state => isNil(state.user.email_address),
    getUserEmailNotVerified: state => state.user.resend_email_verification,
    getSelectedUserMenus: state => {
      const menu = selectMenu(state.user.role, state.isAuthenticated)
      return menu ? menu.items : false
    }
  },
  mutations: {
    login (state, _state) {
      Vue.set(state, 'authenticated', _state)
    },
    setToken (state, accessToken) {
      Vue.set(state, 'accessToken', accessToken)
    },
    setExpireToken (state, expiresIn) {
      Vue.set(state, 'tokenExpiresAt', createDateFromSeconds(expiresIn))
    },
    setUser (state, user) {
      Vue.set(state, 'user', user)
    },
    setProfile (state, profile) {
      Vue.set(state, 'profile', profile)
    },
    throttle (state) {
      const throttle = state.throttle + 1
      Vue.set(state, 'throttle', throttle)
    },
    setModalThrottle (state, show) {
      Vue.set(state, 'modalThrottle', show)
    },
    flushThrottle (state) {
      Vue.set(state, 'throttle', 0)
      Vue.set(state, 'modalThrottle', false)
    },
    clearAllState (state) {
      Object.assign(state, getDefaultUsersState)
    }
  },
  actions: {
    async signin ({ commit, dispatch }, credentials) {
      // eslint-disable-next-line camelcase
      const { access_token, expires_in } = await UserService.postLogin(credentials)

      commit('login', true)
      commit('setToken', access_token)
      commit('setExpireToken', parseInt(expires_in))

      dispatch('detail')
    },
    oauthCallback ({ commit, dispatch }, { user, expiresIn, accessToken }) {
      commit('login', true)
      commit('setToken', accessToken)
      commit('setExpireToken', expiresIn)
      commit('setUser', user)

      dispatch('profile', user)
    },
    async profile ({ commit }, user) {
      const profile = await UserService.getProfile(user)
      commit('setProfile', profile)
    },
    verify ({ dispatch }, verify) {
      return UserService.getVerifyMail(verify)
    },
    async detail ({ commit, dispatch }, user = {}) {
      const roles = ['mahasiswa', 'dosen']
      const majorFieldProp = { mahasiswa: 'kodeprodi', dosen: 'dsnProdiKode' }
      const userData = await UserService.getMe(user)
      commit('setUser', userData)

      if (roles.includes(userData.role)) {
        const profileData = await UserService.getProfile(userData)
        commit('setProfile', profileData)

        if (userData.role === 'mahasiswa') {
          commit('krs/setMahasiswa', profileData, { root: true })
        }

        const fieldProp = prop(userData.role, majorFieldProp)
        const majorFieldId = prop(fieldProp, profileData)

        if (!isNil(majorFieldId)) {
          dispatch('faculty/getActiveSemester', majorFieldId, { root: true })
        }
      }
    },
    unauthenticate ({ commit }) {
      commit('clearAllState')
    },
    async signout ({ commit, state }) {
      if (state.authenticated === true &&
        state.accessToken !== null &&
        state.tokenExpiresAt !== null) {
        await UserService.postLogout()
      }

      commit('clearAllState')
      // Also clear all state from another vuex modules
      commit('assessment/clearAllState', null, { root: true })
      commit('dashboard/clearAllState', null, { root: true })
      commit('faculty/clearAllState', null, { root: true })
      commit('komponenPenilaian/clearAllState', null, { root: true })
      commit('krs/clearAllState', null, { root: true })
    }
  }
}
