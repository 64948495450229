import axios from 'axios'
import Service from './main'
import { User as Url } from './urls'

const CLIENT_ID = parseInt(process.env.VUE_APP_PASSPORT_CLIENT_ID)
const CLIENT_SECRET = process.env.VUE_APP_PASSPORT_CLIENT_SECRET

const oauthGrantAccess = grantType => credentials => Object.assign(credentials, {
  grant_type: grantType,
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET
})

const baseURL = process.env.VUE_APP_API_URL

const auth = axios.create({
  baseURL,
  crossDomain: true
})

export default {
  async postLogin (credentials) {
    const passwordGrant = oauthGrantAccess('password')
    const response = await auth.post(Url.OAUTH_TOKEN, passwordGrant(credentials))
    return response.data
  },
  async getMe (params = {}) {
    const response = await Service.get(Url.USER_DETAIL, params)
    return response.data
  },
  async postLogout () {
    const response = await Service.post(Url.OAUTH_LOGOUT)
    return response.data
  },
  async putChangePassword (passwords) {
    const response = await Service.put(Url.USER_PASSWORD, passwords)
    return response.data
  },
  async putChangeEmail (email) {
    const response = await Service.put(Url.USER_EMAIL, email)
    return response.data
  },
  async getProfile ({ username, role, usernameField }) {
    const field = usernameField || 'id'
    const response = await Service.get(Url.userProfileUri(role), {
      [field]: username
    })
    return response.data
  },
  async getVerifyMail ({ id, hash }) {
    return await Service.get([Url.OAUTH_VERIFY_MAIL, id, hash].join('/'))
  },
  async postResendMail () {
    return await Service.post(Url.OAUTH_RESEND_MAIL)
  },
  async getAppSetting (groupBy) {
    const response = await auth.get(Url.appSetting(groupBy))
    return response.data
  }
}
