import { join } from '@/helpers'

// Lecture
export const Lecture = {
  GET_ALL_SEMESTER: 'sia/dosen/get-all-semester',
  GET_ALL_COURSE: 'sia/dosen/get-all-kelas-matakuliah',
  GET_COURSE: 'sia/dosen/get-kelas-matakuliah',
  GET_ALL_ASSESSMENT_COMPONENT: 'sia/dosen/get-list-komponen-penilaian',
  GET_MASTER_ASSESSMENT_COMPONENT: 'sia/dosen/get-komponen-penilaian-master',
  GET_ACTIVE_SEMESTER: 'sia/dosen/get-semester-aktif',
  CREATE_ASSESSMENT_COMPONENT: 'sia/dosen/add-komponen-penilaian',
  UPDATE_ASSESSMENT_COMPONENT: 'sia/dosen/edit-komponen-penilaian',
  DESTROY_ASSESSMENT_COMPONENT: 'sia/dosen/delete-komponen-penilaian',
  GET_ALL_STUDENTS: 'sia/dosen/get-all-mahasiswa',
  CREATE_GRADE_PER_ASSESS_COMPONENT: 'sia/dosen/add-nilai-perkomponen',
  CREATE_GRADE_PER_CLASS: 'sia/dosen/add-nilai-perkelas',
  CREATE_GRADE_PER_STUDENT: 'sia/dosen/add-nilai-permahasiswa',
  UPDATE_GRADE_PER_STUDENT: 'sia/dosen/edit-nilai-permahasiswa',
  CAN_ENTRY_GRADE_PER_STUDENT: 'sia/dosen/get-cek-periode-input-nilai',
  GET_GUIDANCE_LIST: 'sia/dosen/get-daftar-bimbingan-akademik',
  APPROVE_STUDY_PLANS: 'sia/dosen/approve-krs',
  DASHBOARD_BIMBINGAN_AKADEMIK: 'sia/dashboard-dosen/bimbingan-akademik',
  DASHBOARD_NILAI: 'sia/dashboard-dosen/nilai',
  DASHBOARD_INTERNAL_GRADE_MONITORING: 'sia/dashboard-dosen/summary-nilai',
  SEND_GRADE_ARCHIVE: 'upload'
}
// Student
export const Student = {
  GET_GRADE_TRANSCRIPTS: 'sia/mahasiswa/get-transkrip',
  GET_ALL_SEMESTER: 'sia/mahasiswa/get-semester-mahasiswa',
  GET_ALL_STUDY_PLAN: 'sia/mahasiswa/get-krs',
  GET_ALL_STUDY_RESULTS: 'sia/mahasiswa/get-khs'
}
// Reference
export const Reference = {
  GET_GRADES_REF: 'sia/referensi/get-keterangan-nilai'
}
// Users
export const User = {
  OAUTH_TOKEN: '/oauth/token',
  OAUTH_LOGOUT: '/oauth/logout',
  USER_DETAIL: '/me',
  USER_PASSWORD: '/oauth/changepassword',
  USER_EMAIL: '/oauth/changeemail',
  OAUTH_VERIFY_MAIL: '/oauth/email/verify',
  OAUTH_RESEND_MAIL: '/oauth/email/resend',
  userProfileUri: role => join('', ['sia/', role, '/get-detail-', role]),
  appSetting: group => join('/', ['setting', group])
}
