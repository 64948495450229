import { requireAuth } from '@/helpers/auth'

export default [
  {
    path: '/mahasiswa',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "main" */ '@/layouts/Mahasiswa'),
    meta: {
      label: 'Mahasiswa'
    },
    children: [
      {
        path: '',
        redirect: () => ({ name: 'main' })
      },
      {
        path: 'hasil-studi',
        name: 'mahasiswa.hasil-studi',
        component: () => import(/* webpackChunkName: "mahasiswa" */ '@/views/akademik/HasilStudi'),
        meta: {
          label: 'Kartu Hasil Studi'
        }
      },
      {
        path: 'cetak-kartu',
        name: 'mahasiswa.cetak-kartu',
        component: () => import(/* webpackChunkName: "mahasiswa" */ '@/views/akademik/CetakKartu'),
        meta: {
          label: 'Cetak Kartu'
        }
      }
    ]
  }
]
