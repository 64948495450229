import Vue from 'vue'
import {
  assessmentComponent, equalNumber, isEmpty
} from '@/helpers'
import { LectureService } from '@/services'

const getDefaultKomponenPenilaianState = {
  listKomponenPenilaian: [],
  komponenPenilaian: {
    komPenId: null,
    komPenKlsId: null,
    komPenNama: null,
    komPenPersen: null
  },
  percentageReached: false,
  assessmentComponentExist: false,
  semesterId: null,
  semesterNama: null,
  semesterList: [],
  matakuliah: {},
  loadingMatakuliah: false,
  loadingMahasiswa: false,
  loadingSemester: false,
  loadingKomponenPenilaian: false,
  editState: false
}

export default {
  namespaced: true,
  state: getDefaultKomponenPenilaianState,
  getters: {
    getListKomponenPenilaian: state => state.listKomponenPenilaian,
    getKomponenPenilaian: state => state.komponenPenilaian,
    getAssessmentComponentCompletion: state => state.percentageReached === false ||
      state.assessmentComponentExist === false,
    getMatakuliah: state => state.matakuliah
  },
  mutations: {
    setListKomponenPenilaian (state, listKomponen) {
      Vue.set(state, 'listKomponenPenilaian', listKomponen)
    },
    setKomponenPenilaian (state, komponenPenilaian) {
      Vue.set(state, 'komponenPenilaian', komponenPenilaian)
    },
    setSelectedSemesterId (state, semesterId) {
      Vue.set(state, 'semesterId', semesterId)
    },
    setSelectedSemesterName (state, _semester) {
      Vue.set(state, 'semesterNama', _semester.replace(' ', '_'))
    },
    setSemesterList (state, semesters) {
      Vue.set(state, 'semesterList', semesters)
    },
    setPercentageReach (state, isReach) {
      Vue.set(state, 'percentageReached', isReach)
    },
    setAssessmentComponentExist (state, isReach) {
      Vue.set(state, 'assessmentComponentExist', isReach)
    },
    setMatakuliah (state, matakuliah) {
      Vue.set(state, 'matakuliah', matakuliah)
    },
    resetCompletionCheck (state) {
      Vue.set(state, 'percentageReached', false)
      Vue.set(state, 'assessmentComponentExist', false)
    },
    setEditFormState (state, editState) {
      Vue.set(state, 'editState', editState)
    },
    clearAllState (state) {
      Object.assign(state, getDefaultKomponenPenilaianState)
    },
    setLoadingMatakuliah (state, loading) {
      Vue.set(state, 'loadingMatakuliah', loading)
    },
    setLoadingMahasiswa (state, loading) {
      Vue.set(state, 'loadingMahasiswa', loading)
    },
    setLoadingSemester (state, loading) {
      Vue.set(state, 'loadingSemester', loading)
    },
    setLoadingKomponenPenilaian (state, loading) {
      Vue.set(state, 'loadingKomponenPenilaian', loading)
    }
  },
  actions: {
    async getAllSemester ({ commit, rootState, state }) {
      if (isEmpty(state.semesterList)) {
        commit('setLoadingSemester', true)
        const { data } = await LectureService.getAllSemester(
          rootState.users.user.username
        )
        commit('setSemesterList', data)
        commit('setLoadingSemester', false)
      }
    },
    async getInfoMatakuliah ({ commit, rootState, dispatch }, kelasId) {
      commit('setLoadingMatakuliah', true)
      const { data } = await LectureService.getMatakuliah(
        rootState.faculty.facultyId
      )(
        kelasId, rootState.users.user.username
      )

      commit('setMatakuliah', data)
      commit('setLoadingMatakuliah', false)

      await dispatch('assessment/checkGrantEntryAssessment', {
        mkkurProdiKode: data.mkkurProdiKode,
        klsSemId: data.klsSemId,
        kelasId: kelasId,
        dsnid: rootState.users.user.username
      }, { root: true })
    },
    async getAllKomponenNilai ({ rootState, commit }, kelasId) {
      commit('setLoadingKomponenPenilaian', true)
      const { data } = await LectureService.getAllKomponenNilai(
        rootState.faculty.facultyId
      )(kelasId)

      if (!isEmpty(data)) {
        const percentageReach = assessmentComponent.totalPercentage(data)
        const isComponentReach = equalNumber(percentageReach, assessmentComponent.percentageLimit)
        commit('setPercentageReach', isComponentReach)
      }

      const isComponentExists = !isEmpty(data)
      commit('setAssessmentComponentExist', isComponentExists)
      commit('setLoadingKomponenPenilaian', false)

      return { data }
    },
    async setKomponenNilaiMaster ({ rootState, commit }) {
      const { data } = await LectureService.getKomponenNilaiMaster(
        rootState.faculty.facultyId
      )

      commit('setListKomponenPenilaian', data)
    },
    async getAllMahasiswaByKelasMatkul ({ rootState, commit, state }, klsid) {
      commit('setLoadingMahasiswa', true)
      const { data } = await LectureService.getAllMahasiswaByKelasMatkul(
        rootState.faculty.facultyId
      )(
        klsid, rootState.users.user.username
      )
      commit('setLoadingMahasiswa', false)

      return { data }
    },
    storeKomponenNilai ({ rootState, state }, { komPenKlsId, komPenNama, komPenPersen }) {
      return LectureService.createKomponenNilai(
        rootState.faculty.facultyId
      )({
        sempSemId: state.matakuliah.klsSemId,
        sempProdiKode: state.matakuliah.mkkurProdiKode,
        klsid: komPenKlsId,
        dsnid: rootState.users.user.username
      }, {
        komPenKlsId,
        komPenNama,
        komPenPersen
      })
    },
    updateKomponenNilai ({ rootState, state }, { komPenId, komPenKlsId, komPenNama, komPenPersen }) {
      return LectureService.updateKomponenNilai(
        rootState.faculty.facultyId
      )(komPenId, {
        sempSemId: state.matakuliah.klsSemId,
        sempProdiKode: state.matakuliah.mkkurProdiKode,
        klsid: komPenKlsId,
        dsnid: rootState.users.user.username
      }, {
        komPenKlsId,
        komPenNama,
        komPenPersen
      })
    },
    destroyKomponenNilai ({ rootState, state }, { komPenId, komPenKlsId }) {
      return LectureService.destroyKomponenNilai(
        rootState.faculty.facultyId
      )(komPenId, {
        sempSemId: state.matakuliah.klsSemId,
        sempProdiKode: state.matakuliah.mkkurProdiKode,
        klsid: komPenKlsId,
        dsnid: rootState.users.user.username
      })
    }
  }
}
