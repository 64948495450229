import DosenMenu from './dosen'
import MahasiswaMenu from './mahasiswa'
import InternalMenu from './internal'

export default function (roleMenuFor, disableMenu) {
  const menus = {
    dosen: DosenMenu(disableMenu),
    mahasiswa: MahasiswaMenu(disableMenu),
    internal: InternalMenu(disableMenu)
  }

  return menus[roleMenuFor]
}
