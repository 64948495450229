import Vue from 'vue'
import {
  keys, isEmpty, map, filter, pipe,
  facultyDataMapper,
  facultyWithMajorsDataMapper,
  chartDataMapper,
  parseStatusValue
} from '@/helpers'
import { LectureService as ApiService } from '@/services'

const filterSummaries = data => {
  const { lengkap, belumLengkap } = data

  return (lengkap === 0 && belumLengkap === 0) !== true
}

const filterSummary = pipe(
  map(parseStatusValue),
  filter(filterSummaries)
)

const getDefaultDashboardState = {
  gradeEntriesStatus: [],
  selectedMajors: [],
  bimbinganAkademik: [],
  nilai: [],
  loadingBimbinganAkademik: false,
  loadingNilai: false
}

const getters = {
  getGradeEntriesStatus: state => state.gradeEntriesStatus,
  getChartForFaculties: state => {
    const facultyDataFor = chartDataMapper(
      facultyDataMapper, state.gradeEntriesStatus
    )

    return [{
      name: 'Kosong',
      data: facultyDataFor('NOPROGRESS')
    }, {
      name: 'Belum Lengkap',
      data: facultyDataFor('INCOMPLETE')
    }, {
      name: 'Lengkap',
      data: facultyDataFor('COMPLETE')
    }]
  },
  getSummaryNilai: state => filterSummary(state.nilai),
  getFaculties: state => keys(facultyDataMapper(state.gradeEntriesStatus)),
  getMajors: state => state.selectedMajors
}

const mutations = {
  setEntriesStatus (state, entriesStatus) {
    Vue.set(state, 'gradeEntriesStatus', entriesStatus)
  },
  setSelectedMajors (state, majors) {
    Vue.set(state, 'selectedMajors', majors)
  },
  setBimbinganAkademik (state, bimbinganAkademik) {
    Vue.set(state, 'bimbinganAkademik', bimbinganAkademik)
  },
  setNilai (state, nilai) {
    Vue.set(state, 'nilai', nilai)
  },
  setLoadingBimbinganAkademik (state, loading) {
    Vue.set(state, 'loadingBimbinganAkademik', loading)
  },
  setLoadingNilai (state, loading) {
    Vue.set(state, 'loadingNilai', loading)
  },
  clearAllState (state) {
    Object.assign(state, getDefaultDashboardState)
  }
}

const actions = {
  async getBimbinganAkademik ({ state, rootState, commit }) {
    if (isEmpty(state.bimbinganAkademik)) {
      commit('setLoadingBimbinganAkademik', true)
      const { data } = await ApiService.getDashboardBimbinganAkademik(
        rootState.faculty.facultyId
      )(rootState.users.user.username)
      commit('setBimbinganAkademik', data)
      commit('setLoadingBimbinganAkademik', false)
    }
  },
  async getNilai ({ rootState, commit }) {
    commit('setLoadingNilai', true)
    const { data } = await ApiService.getDashboardNilai(
      rootState.users.user.username
    )
    commit('setNilai', data)
    commit('setLoadingNilai', false)
  },
  async actionAllLectureGradeEntriesStatus ({ commit }) {
    commit('setEntriesStatus', await ApiService.getAllLectureGradeEntries())
  },
  async getFacultyWithMajors ({ state, commit }, facultyName) {
    const majorsDataFor = chartDataMapper(
      await facultyWithMajorsDataMapper(facultyName), state.gradeEntriesStatus
    )

    commit('setSelectedMajors',
      keys(facultyWithMajorsDataMapper(facultyName)(state.gradeEntriesStatus))
    )

    return [{
      name: 'Kosong',
      data: majorsDataFor('NOPROGRESS')
    }, {
      name: 'Belum Lengkap',
      data: majorsDataFor('INCOMPLETE')
    }, {
      name: 'Lengkap',
      data: majorsDataFor('COMPLETE')
    }]
  }
}

export default {
  namespaced: true,
  state: getDefaultDashboardState,
  getters,
  mutations,
  actions
}
