import { requireAuth } from '@/helpers/auth'

export default [
  {
    path: '/login',
    name: 'auth.login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login'),
    meta: {
      label: 'Login'
    }
  },
  {
    path: '/oauth/callback',
    name: 'auth.oauth',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/OAuth2Callback'),
    meta: {
      label: 301
    }
  },
  {
    path: '/logout',
    name: 'auth.logout',
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/Logout'),
    meta: {
      label: 301
    }
  },
  {
    path: '/verifikasi/:id/:hash',
    name: 'auth.verification',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Verifikasi'),
    meta: {
      label: 'Verifikasi Surel'
    }
  }
]
