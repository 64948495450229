export {
  add,
  evolve,
  groupBy,
  head,
  is,
  reduce,
  values,
  filter,
  mapObjIndexed,
  hasPath,
  zipWith,
  takeLast,
  isNil,
  isEmpty,
  find,
  propEq,
  has,
  join,
  map,
  pipe,
  prop,
  keys,
  flatten,
  propIs
} from 'ramda'
