import {
  add,
  evolve,
  find,
  groupBy,
  head,
  is,
  isNil,
  map,
  pipe,
  prop,
  propEq,
  reduce,
  values,
  join,
  filter
} from './utils'

export function lectureNameConcat (names, titleEnd, titleFront) {
  if (isNil(names)) {
    return ''
  }

  let lectureName = [
    names, titleEnd
  ]

  if (!isNil(titleFront)) {
    lectureName = [
      join(' ', [titleFront, names]), titleEnd
    ]
  }

  return lectureName.join(', ').trim()
}

export function parseIfNotNull (val) {
  return val !== null ? val : 'n/a'
}

export function parseIfNotZero (val) {
  return val !== '0' ? val : 'n/a'
}

export const byComponentMapping = item => ({
  krsdtId: item.krsdtId,
  nilai: item.nilai,
  entryby: item.entryby
})

export function mapAssessComponent (data, assessments) {
  return data.map((dataItem) => {
    let grade

    if (dataItem.nilai.length > 0) {
      grade = dataItem.nilai.map(nilaiItem => ({
        [`nilai${nilaiItem.komPenId}`]: nilaiItem.nilai
      }))
    } else {
      grade = assessments.map(compItem => ({
        [`nilai${compItem.komPenId}`]: null
      }))
    }

    return Object.assign(dataItem, ...grade)
  })
}

export function equalNumber (a, b) {
  const num1 = parseInt(a); const num2 = parseInt(b)

  return num1 === num2
}

export function equalSemester (a, b) {
  return Boolean(a === b)
}

export function parseStatusValue (item) {
  return {
    lengkap: parseInt(item.jmlNilaiLengkap),
    belumLengkap: parseInt(item.jmlNilaiBL)
  }
}

const completionGradeMap = item => {
  const percentage = item.DOSEN_KELENGKAPAN_NILAI
  const resultType = percentage === 0 ? 'NOPROGRESS'
    : percentage === 100 ? 'COMPLETE' : 'INCOMPLETE'

  return {
    resultType,
    count: 1
  }
}

const sumGradeMap = prop => vals => reduce(
  (current, val) => evolve({ [prop]: add(val[prop]) }, current),
  head(vals),
  vals
)

const groupSumGrade = (groupOn, sumOn) => {
  return vals => values(map(sumGradeMap(sumOn))(groupBy(prop(groupOn), vals)))
}

export const facultyDataMapper = pipe(
  groupBy(prop('DOSEN_FAKULTAS')),
  map(map(completionGradeMap)),
  map(groupSumGrade('resultType', 'count'))
)

export const facultyWithMajorsDataMapper = facultyName => pipe(
  filter(propEq('DOSEN_FAKULTAS', facultyName)),
  groupBy(prop('DOSEN_PRODI')),
  map(map(completionGradeMap)),
  map(groupSumGrade('resultType', 'count'))
)

const countDataWrapper = item => item.count

export const chartDataMapper = (dataMapper, data) => status => pipe(
  map(findCompletionItemForChart(status)),
  map(countDataWrapper)
)(values(dataMapper(data)))

export const findCompletionItemForChart = findedFor => item => {
  const finded = find(propEq('resultType', findedFor))(item)

  return is(Object, finded) ? finded : { resultType: findedFor, count: 0 }
}
