import Vue from 'vue'
import Router from 'vue-router'
import { join } from '@/helpers'

// All routes
import AuthRoute from './auth'
import MainRoute from './main'
import DosenRoute from './dosen'
import MahasiswaRoute from './mahasiswa'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'notfound',
      component: () => import(/* webpackChunkName: "main" */ '@/views/404.vue'),
      meta: {
        label: 'Halaman tidak ditemukan'
      }
    },
    ...MainRoute,
    ...AuthRoute,
    ...DosenRoute,
    ...MahasiswaRoute
  ]
})

router.beforeEach((to, from, next) => {
  const titlePages = to.matched.slice().reverse().find(r => r.meta && r.meta.label)
  const appName = 'Portal Akademik Universitas Andalas'

  if (titlePages) {
    const action = titlePages.meta.label
    const actionPage = join(' - ', [action, appName])

    document.title = action === 301 ? 'Mengalihkan...' : actionPage
  }

  return next()
})

export default router
