import axios from 'axios'
import AppRouter from '@/routes'
import Store from '@/stores'
import { isNil, join } from '@/helpers'

const baseURL = process.env.VUE_APP_API_URL

const request = axios.create({
  baseURL,
  crossDomain: true
})

const signoutIfUnauthorized = () => {
  Store.dispatch('users/signout').then(() => {
    window.location.reload()
  })
}

const redirectToHome = () => {
  AppRouter.push({
    name: 'main'
  })
}

request.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest'
}

request.interceptors.response.use(({ data }) => {
  if (data.status === 'error') {
    return Promise.reject(data.message)
  }

  return data
}, ({ response }) => {
  if (!isNil(response) && response.status) {
    let respObj = response

    if (response.status === 403) {
      signoutIfUnauthorized()
    }

    if (response.status === 401) {
      redirectToHome()
    }

    if (response.status === 400) {
      respObj = {
        message: 'Terjadi kesalahan saat mengirim parameter data.'
      }
    }

    return Promise.reject(respObj)
  }
})

async function getHeader (facultyId) {
  const faculty = !isNil(facultyId) ? { 'X-FacultyCode': facultyId } : {}
  const token = Store.state.users.accessToken
  return Object.assign(faculty, { Authorization: join(' ', ['Bearer', token]) })
}

const consumeApi = method => async (url, params, facultyId, data = {}) => {
  const headers = await getHeader(facultyId)
  return await request.request({
    url,
    method,
    headers,
    params,
    data
  })
}

export default {
  async get (url, params, facultyId) {
    return await consumeApi('GET')(url, params, facultyId)
  },
  async post (url, data, facultyId, params = {}) {
    return await consumeApi('POST')(url, params, facultyId, data)
  },
  async patch (url, data, facultyId, params = {}) {
    return await consumeApi('PATCH')(url, params, facultyId, data)
  },
  async put (url, data, facultyId, params = {}) {
    return await consumeApi('PUT')(url, params, facultyId, data)
  },
  async delete (url, facultyId, params) {
    return await consumeApi('DELETE')(url, params, facultyId)
  },
  async upload (url, formData, onUploadProgress) {
    const headers = await getHeader()
    return await request.request({
      url,
      headers,
      method: 'POST',
      data: formData,
      onUploadProgress
    })
  }
}
