import { requireAuth } from '@/helpers/auth'

export default [
  {
    path: '',
    redirect: 'dashboard',
    name: 'main',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "main" */ '@/layouts/Main'),
    meta: {
      label: 'Main'
    },
    children: [
      {
        path: 'dashboard',
        alias: '',
        name: 'main.dashboard',
        component: () => import(/* webpackChunkName: "main" */ '@/views/Dashboard'),
        meta: {
          label: 'Dashboard'
        }
      },
      {
        path: '/profil',
        name: 'main.user-profile',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Profile'),
        meta: {
          label: 'Profil Pengguna'
        }
      }
    ]
  }
]
