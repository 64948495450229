import Vue from 'vue'
import { LectureService } from '@/services'
import {
  isNil, isEmpty, filter, map, pipe, Sheet,
  byComponentMapping, parseDate
} from '@/helpers'

const filterNonNullGrade = item => !isNil(item.nilai)

const getDefaultAssessmentState = {
  lectureCanEntryAssessment: false,
  isGradeEntrySchedule: false,
  gradeEntryStart: null,
  gradeEntryEnd: null,
  templateType: null,
  templateFile: null,
  loadingGrantAccess: true
}

export default {
  namespaced: true,
  state: getDefaultAssessmentState,
  mutations: {
    grantAccess (state, access) {
      Vue.set(state, 'lectureCanEntryAssessment', access)
    },
    setGrantGradeEntry (state, access) {
      Vue.set(state, 'isGradeEntrySchedule', access)
    },
    setGradeEntryStart (state, start) {
      Vue.set(state, 'gradeEntryStart', start)
    },
    setGradeEntryEnd (state, end) {
      Vue.set(state, 'gradeEntryEnd', end)
    },
    clearAllState (state) {
      Object.assign(state, getDefaultAssessmentState)
    },
    setTemplateType (state, _templateType) {
      Vue.set(state, 'templateType', _templateType)
    },
    setTemplateFile (state, _templateFile) {
      Vue.set(state, 'templateFile', _templateFile)
    },
    setLoadingGrantAccess (state, loading) {
      Vue.set(state, 'loadingGrantAccess', loading)
    }
  },
  actions: {
    async checkGrantEntryAssessment ({ commit, rootState }, { mkkurProdiKode, klsSemId, kelasId }) {
      commit('setLoadingGrantAccess', true)
      const { inputNilai, periode } = await LectureService.checkGrantAssessment(
        rootState.faculty.facultyId
      )(mkkurProdiKode, klsSemId, rootState.users.user.username, kelasId)

      commit('setGrantGradeEntry', !isEmpty(periode))
      if (!isEmpty(periode)) {
        commit('setGradeEntryStart', parseDate(`${periode[0]}T00:00:00`))
        commit('setGradeEntryEnd', parseDate(`${periode[1]}T23:59:59`))
      }
      commit('grantAccess', Boolean(inputNilai))
      commit('setLoadingGrantAccess', false)
    },
    flushGrantAccess ({ commit }) {
      commit('setGrantGradeEntry', false)
      commit('setGradeEntryStart', null)
      commit('setGradeEntryEnd', null)
      commit('grantAccess', false)
    },
    async validateAssessmentComponent ({ commit, dispatch }, kelasId) {
      const { data } = await dispatch('komponenPenilaian/getAllKomponenNilai', kelasId, { root: true })

      return data
    },
    async createGradeByStudent ({ rootState }, { kelasId, grade }) {
      const krsdtId = grade[0].krsdtId
      const finalGrade = grade.map(item => ({
        kompenId: item.kompenId,
        nilai: item.nilai,
        entryby: item.entryby
      }))

      return await LectureService.createGradeByStudent(
        rootState.faculty.facultyId
      )({
        sempSemId: rootState.komponenPenilaian.matakuliah.klsSemId,
        sempProdiKode: rootState.komponenPenilaian.matakuliah.mkkurProdiKode,
        dsnid: rootState.users.user.username,
        klsid: kelasId
      }, {
        krsdtId,
        nilai_per_mahasiswa: finalGrade
      })
    },
    async createGradeByComponent ({ rootState }, { kelasId, formData, komPenId }) {
      const data = pipe(
        filter(filterNonNullGrade)
      )(formData)

      return await LectureService.createGradeByAssessComponent(
        rootState.faculty.facultyId
      )({
        sempSemId: rootState.komponenPenilaian.matakuliah.klsSemId,
        sempProdiKode: rootState.komponenPenilaian.matakuliah.mkkurProdiKode,
        dsnid: rootState.users.user.username,
        klsid: kelasId
      }, {
        komPenId,
        nilai_per_komponen: data
      })
    },
    async createGradeByClass ({ rootState }, { kelasId, formData }) {
      const data = pipe(
        filter(filterNonNullGrade)
      )(formData)

      return await LectureService.createGradeByClass(
        rootState.faculty.facultyId
      )({
        sempSemId: rootState.komponenPenilaian.matakuliah.klsSemId,
        sempProdiKode: rootState.komponenPenilaian.matakuliah.mkkurProdiKode,
        dsnid: rootState.users.user.username,
        klsid: kelasId
      }, {
        nilai_per_kelas: data
      })
    },
    async sentTemplate ({ state }) {
      const templates = {
        AllKomponen: 'nilai_per_kelas',
        PerKomponen: 'nilai_per_komponen'
      }

      const template = templates[state.templateType]

      return await LectureService.sendGradeArchive({
        grade: state.templateFile,
        template
      })
    },
    async sentStudentGrade ({ state, dispatch }, {
      assessmentComponent, kelasId, assessComponentId
    }) {
      const data = await dispatch('prepareAssessment', assessmentComponent)
      await dispatch('sentTemplate')

      if (state.templateType === 'PerKomponen') {
        const formData = data.map(byComponentMapping)

        return await dispatch('createGradeByComponent', {
          kelasId,
          komPenId: assessComponentId,
          formData
        })
      }

      if (state.templateType === 'AllKomponen') {
        return await dispatch('createGradeByClass', {
          kelasId,
          formData: data
        })
      }
    },
    async prepareAssessment ({ state, rootState }, assessmentComponent) {
      const [assCompKey, rowData] = await Sheet.readAssessmentTemplate(state.templateFile, state.templateType)
      return Sheet.normalizeRowSheet(
        assessmentComponent, assCompKey, rowData,
        rootState.users.user.username
      )
    },
    async submitAssessment ({ rootState, state, dispatch }, { kelasId, assessment }) {
      const mappingGrade = item => ({
        kompenId: item.komPenId,
        krsdtId: item.krsdtId,
        nilai: item.nilai,
        entryby: rootState.users.user.username
      })

      const submitted = pipe(
        filter(filterNonNullGrade),
        map(mappingGrade)
      )

      return await dispatch('createGradeByStudent', { kelasId, grade: submitted(assessment) })
    }
  }
}
